import { BaseError } from "make-error";
import { sleep } from "../utils/time";

export class ForbiddenError extends BaseError {}

export class ServiceUnavailableError extends BaseError {}

/** Base client for Quartz APIs. */
export class BaseClient {
  constructor(
    private readonly url: string,
    private readonly authorizationHeader: () => Promise<string | null> = async () => null
  ) {}

  // this is to get  is used to retrieve the response body as a binary object.
  protected async httpGetBlob(path: string, controller?: AbortController): Promise<any> {
    // Loop to retry in case of transient connection errors
    let resp: any;
    while (true) {
      try {
        const headers: { [name: string]: string } = {};
        const authorizationHeader = await this.authorizationHeader();
        if (authorizationHeader !== null) {
          headers["Authorization"] = authorizationHeader;
        }
        resp = await fetch(`${this.url}${path}`, {
          headers,
          signal: controller?.signal,
          mode: "cors",
        });
        break;
      } catch (err: any) {
        if (err.name === "AbortError") {
          throw err;
        }
        console.error(err);
        await sleep(1_000, controller);
      }
    }
    if (resp.status === 410) {
      throw new Error("410");
    }
    if (resp.status === 500) {
      throw new Error("Unexpected technical error");
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    return resp.blob();
  }

  protected async httpPostBlob(
    path: string,
    body: any,
    controller?: AbortController
  ): Promise<any> {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    const authorizationHeader = await this.authorizationHeader();
    if (authorizationHeader !== null) {
      headers["Authorization"] = authorizationHeader;
    }
    const json_body = JSON.stringify(body);
    const resp = await fetch(`${this.url}${path}`, {
      method: "POST",
      mode: "cors",
      headers,
      body: json_body,
      signal: controller?.signal,
    });
    if (resp.status === 410) {
      throw new Error("410");
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    return resp.blob();
  }

  protected async httpGet(path: string, controller?: AbortController): Promise<any> {
    // Loop to retry in case of transient connection errors
    let resp: any;
    while (true) {
      try {
        const headers: { [name: string]: string } = {};
        const authorizationHeader = await this.authorizationHeader();
        if (authorizationHeader !== null) {
          headers["Authorization"] = authorizationHeader;
        }
        resp = await fetch(`${this.url}${path}`, {
          headers,
          signal: controller?.signal,
          mode: "cors",
        });
        break;
      } catch (err: any) {
        if (err.name === "AbortError") {
          throw err;
        }
        console.error(err);
        await sleep(1_000, controller);
      }
    }
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    const body_res = await resp.json();
    if (resp.status === 504) {
      throw new Error(body_res.message);
    }
    if (resp.status === 404) {
      throw new Error(body_res.message);
    }
    if (resp.status === 410) {
      throw new Error(body_res.message);
    }
    if (resp.status === 500) {
      throw new Error("Unexpected technical error");
    }
    if (resp.status !== 200) {
      throw new Error(`Unexpected status ${resp.status}`);
    }
    return body_res;
  }

  protected async httpPost(path: string, body: any, controller?: AbortController): Promise<any> {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    const authorizationHeader = await this.authorizationHeader();
    if (authorizationHeader !== null) {
      headers["Authorization"] = authorizationHeader;
    }
    const json_body = JSON.stringify(body);
    const resp = await fetch(`${this.url}${path}`, {
      method: "POST",
      mode: "cors",
      headers,
      body: json_body,
      signal: controller?.signal,
    });
    const resp_body = await resp.json();
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 504) {
      throw new Error(resp_body.message);
    }
    if (resp.status === 404) {
      throw new Error(resp_body.message);
    }
    if (resp.status === 410) {
      throw new Error(resp_body.message);
    }
    if (resp.status === 409) {
      throw new Error(resp_body.message);
    }
    if (resp.status !== 200) {
      throw new Error(`Unexpected error ${resp.status}`);
    }
    return resp_body;
  }

  protected async httpDelete(path: string, body: any, controller?: AbortController): Promise<any> {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    const authorizationHeader = await this.authorizationHeader();
    if (authorizationHeader !== null) {
      headers["Authorization"] = authorizationHeader;
    }
    const json_body = JSON.stringify(body);
    const resp = await fetch(`${this.url}${path}`, {
      method: "DELETE",
      mode: "cors",
      headers,
      body: json_body,
      signal: controller?.signal,
    });
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 504) {
      throw new ServiceUnavailableError();
    }
    if (resp.status === 410) {
      throw new Error("410");
    }
    if (resp.status === 404) {
      throw new Error("404");
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    const resp_body = await resp.json();
    return resp_body;
  }
  protected async httpPut(path: string, body: any, controller?: AbortController): Promise<any> {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    const authorizationHeader = await this.authorizationHeader();
    if (authorizationHeader !== null) {
      headers["Authorization"] = authorizationHeader;
    }
    const json_body = JSON.stringify(body);
    const resp = await fetch(`${this.url}${path}`, {
      method: "PUT",
      mode: "cors",
      headers,
      body: json_body,
      signal: controller?.signal,
    });
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 504) {
      throw new ServiceUnavailableError();
    }
    const resp_body = await resp.json();
    if (resp.status === 410) {
      throw new Error("410");
    }
    if (resp.status === 404) {
      throw new Error("404");
    }
    if (resp.status === 500) {
      throw new Error(resp_body.message);
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    return resp_body;
  }
}
